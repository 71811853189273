import React from "react";
import { Link } from "react-router-dom";
import Banner from "../components/Banner";
import Services from "../components/Services";
import QA from "../components/TravelingTips";
import Destinations from "../components/Destinations";
import FeaturedRooms from "../components/FeaturedRooms";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";


const home = () => {
  return (
    <>
    <Navbar />
    <Banner
        title="Mekong Explore."
          subtitle="Where the happiness begins"
      >


          <Link to="/rooms" className="btn-primary">
            Check in here
          </Link>
      </Banner>


   <Destinations />

      <Services />



      <FeaturedRooms />

      <QA/>

      <Contact/>
      <Footer/>
      
      </>
  
  );
};

export default home;
