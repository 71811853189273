import React from "react";
import logo from "../media/taniti.png";

const ResortBanner = ({ children, title, subtitle }) => {
  return (
  <div className="resort-banner">

  <img className="logo" src={logo} alt="Mekong Logo" width="100px" height="100px"/>  
  
      <h1>{title}</h1>
      <div />
      <p>{subtitle}</p>
      {children}
      </div>
 
  );
};

export default ResortBanner;
