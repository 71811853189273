import React from "react";
import logo from "../media/taniti.png";
import videoBg from "../media/coconut-tree.mp4";

const Banner = ({ children, title, subtitle }) => {
  return (
  <div id="Home" className="banner">

    <video id="vid" src={videoBg} type="video/mp4"
       autoPlay muted loop>

    </video>

    <div id="content">

      <img className="logo" src={logo} alt="Mekong Logo" width="100px" height="100px"/>  
  
      <h1>{title}</h1>
  
      <p>{subtitle}</p>

      {children}

    </div>

  </div>
 
  );
};

export default Banner;
