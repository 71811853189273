import React from "react";
import ResortBanner from "../components/ResortBanner";
import { Link } from "react-router-dom";
import RoomsContainer from "../components/RoomsContainer";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";


const Rooms = () => {
  return (
    <>
    <Navbar/>
     
    <ResortBanner title="All Resort Rooms">
                
          <Link to="/" className="btn-primary">
            Back to Home
          </Link>
        </ResortBanner>
   
      <RoomsContainer />
     
      <Footer/>
    </>
  );
};

export default Rooms;
