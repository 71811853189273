import React, { Component } from 'react';
import Scroll from 'react-scroll';

import { about } from '../configs';


class Footer extends Component
{
 
  render() {
    return (
      <div id="Footer" className="footer">

      <div className="contact-container">

      <span>Mekong Explore @2022</span>

      <a
            href={about.website}
            target="_blank"
            rel="noopener noreferrer"
          >

      <span>Made by Juni Nguyen @codingLEAF</span>

      </a>

      <span>Photos from <a rel="noreferrer" href="https://unsplash.com/" alt="photos from Unplash" target="_blank" >Unsplash</a></span>

      </div>

        
        <div className="contact-container">
          <a
            href={about.address}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon is-large" data-toggle="tooltip" data-placement="left" title="Address">
              <i className="fas fa-map-marker-alt fa-2x purple"></i>
            </span>
            Mekong Delta, Vietnam
           
          </a>
         
            <a href={about.email} target="_blank" rel="noopener noreferrer">
             <span className="icon is-large" data-toggle="tooltip" data-placement="left" title="Email">
               <i className="fas fa-envelope fa-2x purple"></i>
               </span>

               mekong@explore.com
              
          </a>

           <a href={about.phone} target="_blank" rel="noopener noreferrer">
             <span className="icon is-large" data-toggle="tooltip" data-placement="left" title="Phone">
               <i className="fas fa-phone-square fa-2x purple"></i>
               </span>
               +1 (123) 456-7890
          </a>
  
        </div>
        
      <button
          className="button is-light is-medium scrollToTopBtn"
          onClick={() => {
            Scroll.animateScroll.scrollToTop();
          }}
        >
          <span>
            Top
          </span>
        </button>
   
      </div>
    );
  }
}

export default Footer;
