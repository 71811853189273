import React, { Component } from "react";
import { FaUmbrellaBeach, FaSeedling, FaAnchor, FaWater } from "react-icons/fa";
import Title from "./Title";

export default class Destinations extends Component {
  state = {
    services: [
      {
        icon: <FaUmbrellaBeach />,
        title: "Tropical Sandy Beaches",
        info:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias molestias eius libero.Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias molestias eius libero.",
        booking: 100,
        image: "https://images.unsplash.com/photo-1629663432612-9a6e5ec8537c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1331&q=80",
        tour: "snorkeling"
      },
      {
        icon: <FaWater />,
        title: "Charming Rivers",
        info:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias molestias eius libero.Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias molestias eius libero.",
          booking: 50,
          image: "https://images.unsplash.com/photo-1519458498024-9448c70cbae7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80",
          tour: "boat rides"
      },
      {
        icon: <FaSeedling />,
        title: "Scenic Farmland",
        info:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias molestias eius libero.Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias molestias eius libero.",
          booking: 80,
          image: "https://images.unsplash.com/photo-1568342840184-8964c48c8b9f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80",
          tour: "bicycle rides"
      },
      {
        icon: <FaAnchor />,
        title: "Peaceful Harbor",
        info:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias molestias eius libero.Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias molestias eius libero.",
          booking: 80,
          image: "https://images.unsplash.com/photo-1649246481962-c59570fcc0b4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80",
          tour: "fishing tours",
      }
    ]
  };
  render() {
    return (
      <section id="Destinations" className="explore">
        <Title title="Top Destinations" />
        <div className="services-center">
          {this.state.services.map(item => {
            return (
              <article key={`item-${item.title}`} className="service">
                <span>{item.icon}</span>
                <h6>{item.title}</h6>
                <p className="explore-details">{item.info}</p>
               
      <article className="room">        
      <div className="img-container">
        <img src={item.image} alt="top destinations" />
        <div className="price-top">
          <h6>${item.booking}</h6>
          <p>per day</p>
        </div>
        <a href="#Contact" className="btn-primary explore-link">
          Booking
        </a>
      </div>
      <p className="explore-info">{item.tour}</p>
      </article>
      </article>
      );
      })}
      </div>
      </section>
    );
  }
}
