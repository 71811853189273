import React, { Component} from 'react';
import ContactForm from './ContactForm/ContactForm';
import Title from "../Title";

class Contact extends Component
{
 
  render ()
  {
   
    return (
  
      <section id="Contact" className="contact">
             <Title title="Contact For Booking" />
             
          <div className="container">
            
              <div className="content">
                <ContactForm />
              </div>
             </div>
       
        </section>
  
    
    );
  }
}

export default Contact;
