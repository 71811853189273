const about = {
  website: 'https://www.juninguyen.tech/',
  email: 'mailto:mekong@explore.com',
  phone: 'tel:1234567890',
  address: 'https://www.google.com/maps/d/u/0/viewer?mid=1nfpFMrTAa7xmgMoLIiGl8Z1ocdo&ll=9.79478160846581%2C105.13668865000002&z=8',
 
};


export { about};
