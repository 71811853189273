import React, { Component } from "react";
import logo from "../media/taniti.png";

export default class NavbarRoomPage extends Component {
  
  render() {
  return (
  <nav className="navbar fixed-top navbar-expand-lg">
     <a href="/#Home">
              <img className="logo-brand" src={logo} alt="Mekong Logo" width="80px" height="80px"/>
     </a>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
  <i className="fas fa-bars nav-icon"></i>
  </button>
  <div className="nav justify-content-center collapse navbar-collapse" id="navbarNav">
  <ul className="nav-link navbar-nav show-nav">
            <li className="nav-item active">
              <a href="/#Home">Home</a>
            </li>

            <li className="nav-item">
              <a href="/#Destinations">Explore</a>
            </li>

            <li className="nav-item">
              <a href="/#Featured">Lodging</a>
             
            </li>
            <li className="nav-item">
            <a href="/#Services">Services</a>
            </li>
            <li className="nav-item">
            <a href="/#Q&A">Tourist Tips</a>
            </li>
            <li className="nav-item">
              <a href="/#Contact">Contact</a>
            </li>

          </ul>
  </div>
</nav>
    );
  }
}
